import { logAndThrowSentryError } from '../../utils/errors';
import { ApolloClient } from '../clients';
import {
  CREATE_CART,
  GET_CART,
  CART_LINES_ADD,
  CART_LINES_UPDATE,
  CART_LINES_REMOVE,
  CART_DISCOUNT_CODES_UPDATE,
} from './queries';

export const createCart = (): Promise<Shopify.CartCreatePayload | null | undefined> =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: CREATE_CART,
    variables: {
      input: {},
    },
  }).then(({ data }) => data?.cartCreate);

export const getCart = (cartId: string): Promise<Shopify.Cart> =>
  ApolloClient.query<Shopify.QueryRoot>({
    query: GET_CART,
    variables: {
      cartId,
    },
  }).then(({ data, error }) => {
    if (error) logAndThrowSentryError('getCheckout', error);
    return data?.node as Shopify.Cart;
  });

export const cartLineAdd = (
  cartId: string,
  merchandiseId: string,
  quantityNumber: number,
): Promise<Shopify.CartLinesAddPayload | null | undefined> =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: CART_LINES_ADD,
    variables: {
      cartId,
      lines: [{ merchandiseId, quantity: quantityNumber }],
    },
  }).then(({ data }) => data?.cartLinesAdd);

export const cartLinesAdd = (
  cartId: string,
  variants: Shopify.ProductVariant[],
  quantityNumber: number,
): Promise<Shopify.CartLinesAddPayload | null | undefined> =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: CART_LINES_ADD,
    variables: {
      cartId,
      lines: variants.map((variant) => ({
        merchandiseId: variant.id,
        quantity: quantityNumber,
      })),
    },
  }).then(({ data }) => data?.cartLinesAdd);

export const cartLinesRemove = (
  cartId: string,
  lineItemIds: string[],
): Promise<Shopify.CartLinesRemovePayload | null | undefined> =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: CART_LINES_REMOVE,
    variables: {
      cartId,
      lineIds: lineItemIds,
    },
  }).then(({ data }) => data?.cartLinesRemove);

export const cartLinesUpdate = (
  cartId: string,
  lineItems: Shopify.CartLineUpdateInput[],
): Promise<Shopify.CartLinesUpdatePayload | null | undefined> =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: CART_LINES_UPDATE,
    variables: {
      cartId,
      lines: lineItems,
    },
  }).then(({ data }) => data?.cartLinesUpdate);

export const cartDiscountCodesUpdate = (
  cartId: string,
  discountCodes: string[],
): Promise<Shopify.CartDiscountCodesUpdatePayload | null | undefined> =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: CART_DISCOUNT_CODES_UPDATE,
    variables: {
      cartId,
      discountCodes,
    },
  }).then(({ data }) => data?.cartDiscountCodesUpdate);
