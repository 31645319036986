import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import Image from 'next/image';
import Link from 'next/link';
import { utils } from '@makeship/core';

import posthog from 'posthog-js';
import { S1, P2, Overline } from '../Typography';

import { useStore } from '../../store';
import config from '../../../config.json';
import { isUserLoggedIn } from '../../utils/analytics';

const { routes } = config;

type DrawerProps = {
  showMenu: boolean;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const DrawerWrapper = styled.div<{ showMenu: boolean }>`
  display: none;
  transform: ${({ showMenu }) => (showMenu ? 'translate(0)' : 'translate(-105%)')};
  transition: transform 0.25s ease-in-out;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.headerBreakpoint}px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    height: 550px;
    width: calc(100% - 16px);
    max-width: 398px;
    padding: 16px;
    background-color: ${({ theme }) => theme.colors.neutral1};
    box-shadow: 0px 2px 16px ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.medium)};
    z-index: 11;
  }
`;

const DrawerHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
`;

const DrawerHeaderText = styled(Overline)<{ show: boolean }>`
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
`;

const CancelIconWrapper = styled.div`
  cursor: pointer;
`;

const BackIconWrapper = styled.div<{ show: boolean }>`
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  cursor: pointer;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;

const MenuNavList = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuNavItemStyles = css`
  & > p {
    border-bottom: 2px solid ${({ theme }) => theme.colors.transparent};
    text-decoration: none;
  }
  &:hover,
  :focus-visible {
    outline: none;
    & > p {
      border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
    }
  }
`;

const MenuNavItem = styled.a`
  margin-bottom: 24px;
  ${MenuNavItemStyles};

  & > p {
    display: inline-block;
  }
`;

const ExploreCampaignsNavItem = styled(MenuNavItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const NewText = styled.span`
  color: ${({ theme }) => theme.colors.greenNewTag};
`;

type NavItem = {
  title: string;
  href: string;
  id?: string;
  isNew?: boolean;
};

const navItems: NavItem[] = [
  {
    title: 'Explore Petitions',
    href: routes.shopPetitions,
    id: 'explore-petitions-mobile-cta',
    isNew: false,
  },
  {
    title: 'Explore Campaigns',
    href: routes.shopLive,
    id: 'explore-campaigns-mobile-cta',
    isNew: false,
  },
  {
    title: 'Launch Campaign',
    href: routes.launchCampaign,
    id: 'launch-campaign-mobile-cta',
    isNew: false,
  },
];

const campaignStages: NavItem[] = [
  {
    title: 'Live Campaigns',
    href: routes.shopLive,
  },
  {
    title: 'Top Campaigns',
    href: routes.shopTop,
  },
  {
    title: 'New Arrivals',
    href: routes.shopNewArrivals,
  },
  {
    title: 'Coming Soon',
    href: routes.shopComingSoon,
  },
  {
    title: 'Past Campaigns',
    href: routes.shopPast,
  },
];

const Drawer: React.FC<DrawerProps> = ({ showMenu, setShowMenu }: DrawerProps) => {
  const { state } = useStore();

  const [showCampaigns, setShowCampaigns] = useState(false);
  const drawerRef = useRef<HTMLDivElement>(null);
  const exploreCampaignsNavTitle = 'Explore Campaigns';

  const linkItems: NavItem[] = [
    {
      title: state.user?.accessToken ? 'My Account' : 'Sign In/Up',
      href: state.user?.customer ? routes.account : routes.login,
    },
    {
      title: 'Help Center',
      href: routes.helpCenter,
    },
  ];

  const handleClick = useCallback((e: MouseEvent) => {
    // outside dropdown div
    if (!!drawerRef.current && !drawerRef.current.contains(e.target as Node)) {
      clear();
    }
  }, []);

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  const clear = () => {
    setShowMenu(false);
  };

  const handleNavItemClick = (title: string) => {
    posthog.capture('header_navItem_click', {
      nav_item_title: title,
      is_logged_in: isUserLoggedIn(state.user),
    });

    if (title === exploreCampaignsNavTitle) {
      setShowCampaigns(true);
    } else {
      clear();
    }
  };

  return (
    <DrawerWrapper showMenu={showMenu} ref={drawerRef}>
      <DrawerHeader>
        <BackIconWrapper show={showCampaigns} onClick={() => setShowCampaigns(false)}>
          <Image src="/assets/icons/back-icon.svg" width="24" height="12" quality={90} />
        </BackIconWrapper>
        <DrawerHeaderText show={showCampaigns}>Explore Campaigns</DrawerHeaderText>
        <CancelIconWrapper onClick={() => clear()}>
          <Image src="/assets/icons/close-icon.svg" width="16" height="16" quality={90} />
        </CancelIconWrapper>
      </DrawerHeader>
      {showCampaigns ? (
        <>
          <MenuNavList>
            {campaignStages.map((item) => (
              <Link key={item.title} href={item.href} passHref>
                <MenuNavItem onClick={() => handleNavItemClick(item.title)}>
                  <S1>{item.title}</S1>
                </MenuNavItem>
              </Link>
            ))}
          </MenuNavList>
        </>
      ) : (
        <>
          <MenuNavList>
            {navItems.map((item) => {
              if (item.title !== exploreCampaignsNavTitle) {
                return (
                  <Link key={item.title} href={item.href} passHref>
                    <MenuNavItem data-testid={item.id} onClick={() => handleNavItemClick(item.title)}>
                      <S1>
                        <NewText>{item.isNew && 'New! '}</NewText>
                        {item.title}
                      </S1>
                    </MenuNavItem>
                  </Link>
                );
              }
              return (
                <ExploreCampaignsNavItem
                  data-testid="show-campaigns"
                  key={item.title}
                  onClick={() => handleNavItemClick(item.title)}
                >
                  <S1>{item.title}</S1>
                  <Image src="/assets/icons/right-arrow-menu.svg" width="24" height="14" quality={90} />
                </ExploreCampaignsNavItem>
              );
            })}
          </MenuNavList>
          <LinksContainer>
            {linkItems.map((item) => (
              <Link key={item.title} href={item.href} passHref>
                <MenuNavItem onClick={() => handleNavItemClick(item.title)}>
                  <P2>{item.title}</P2>
                </MenuNavItem>
              </Link>
            ))}
          </LinksContainer>
        </>
      )}
    </DrawerWrapper>
  );
};

export default Drawer;
