import React from 'react';
import Image from 'next/image';
import styled, { CSSProp } from 'styled-components';
import { utils } from '@makeship/core';
import { P2Styles } from '../Typography';
import { NavTheme } from '../../types/common';

export type SearchBarProps = {
  type?: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  spellcheck?: string;
  customStyles?: CSSProp;
  inputRef?: React.RefObject<HTMLInputElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
  navTheme?: NavTheme;
};

const InputWrapper = styled.div<{ customStyles: CSSProp | undefined; navTheme: NavTheme | undefined }>`
  display: flex;
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)} !important;
  background-color: ${({ theme, navTheme }) =>
    navTheme === 'dark' ? theme.colors.neutral1 : utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  padding: 4px 15px;
  width: 200px;
  height: 32px;
  border-radius: 32px;
  align-items: center;
  ${({ customStyles }) => customStyles && customStyles};
`;

const Input = styled.input`
  ${P2Styles}
  background-color: transparent;
  border: 2px solid transparent;
  margin-left: 5px;
  width: 100%;
  color: ${({ theme }) => theme.colors.neutral7};

  &::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
    opacity: 1;
  }

  &:hover::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
  }

  &:focus {
    outline: none;
  }

  &:focus::placeholder {
    opacity: 0.5;
  }

  &:active {
    border: 2px solid transparent;
  }
`;

const SearchIcon = styled(Image)`
  width: 16px;
  height: 16px;
`;

const SearchBar: React.FC<SearchBarProps> = ({
  type,
  placeholder,
  value,
  onChange,
  customStyles,
  inputRef,
  navTheme,
  ...rest
}: SearchBarProps) => (
  <InputWrapper customStyles={customStyles} navTheme={navTheme}>
    <SearchIcon
      src={`/assets/icons/search-icon${navTheme === NavTheme.Dark || navTheme === NavTheme.Light ? '' : '-blue'}.svg`}
      alt="magnifying glass icon"
      width={16}
      height={16}
    />
    <Input type={type} placeholder={placeholder} value={value} onChange={onChange} ref={inputRef} {...rest} />
  </InputWrapper>
);

export default SearchBar;
