import { Alpha, Breakpoints, Colors, Fonts, Theme } from '@makeship/core';
import { DefaultTheme } from 'styled-components';

export const colors: Colors = {
  neutral7: '#141414',
  neutral6: '#282828',
  neutral5: '#505050',
  neutral4: '#7C7C7C',
  neutral3: '#ABABAB',
  neutral2: '#DDDDDD',
  neutral1: '#FFFFFF',
  primaryLight: '#668EFF',
  primaryLighter: '#AEAAEE',
  primary: '#5349ED',
  primaryDark: '#2D23B6',
  primaryDarker: '#2C0073',
  darkTextOnPink: '#22005A',
  secondaryLight: '#7FF25F',
  secondary: '#4EE12A',
  secondaryDark: '#1B7D02',
  greenNeutral: '#7ff260',
  accentLight: '#FDEB9F',
  accent: '#F5F0D5',
  accentDark: '#E7E1C0',
  info: '#5349ED',
  success: '#028848',
  warning: '#EB9D10',
  error: '#C0323B',
  pink1: '#EE4DFF',
  lightPink: '#F49DF3',
  gitdPink: '#FF1BB1',
  palePink: '#E4DEF0',
  greenLight: '#63F25F',
  blueLight: '#3DBEFF',
  blueLight2: '#8BD8FF',
  bluePrimary: '#009CEB',
  blueDark: '#0085C9',
  transparent: 'transparent',
  background: '#F5F7F8',
  background2: '#C7C2C2',
  background3: '#B6EBFF',
  background4: '#E4DEF0',
  containerBackground: '#EEEEEE',
  yellow: '#FFEA94',
  oceanBlue: '#ABCBFF',
  newBadge: '#87DCE1',
  sliderBackground: '#F4F1FB',
  failedBadge: '#23DA8B',
  lowStock: '#F16E00',
  periwinkle: '#91ADFE',
  // Brand Refresh
  midnight4: '#1F00760A',
  midnight8: '#1F007614',
  midnight16: '#1F007614',
  midnight33: '#1F007654',
  midnight66: '#1F0076A8',
  midnight88: '#1F0076E0',
  midnight: '#1F0076',
  cloud: '#DAD2FF',
  nova: '#5819A9',
  deep: '#2D23B6',
  blurple: '#5349ED',
  aurora: '#C73AA2',
  sky66: '#668EFFA8',
  sky: '#668EFF',
  fuel: '#4EE12A',
  flash: '#FF327C',
  link: '#7C4DFF',
  linkHover: '#6258EE',
  footerBorder: '#A895C7',
  greenNewTag: '#3db121',
  prelaunchPurple: '#9B51E0',
  gitdPurple: '#31068B',
  placeholder: '#616161',
  makeshipBlack: '#141414',
};

const breakpoints: Breakpoints = {
  mobile: 480,
  tablet: 768,
  largeTablet: 900,
  desktop: 1080,
  largeDesktop: 1280,
  maxContent: 1440,
  maxBackground: 1920,
  headerBreakpoint: 1050,
};

export const alpha: Alpha = {
  extraDark: 75,
  dark: 60,
  medium: 33,
  light: 15,
  extraLight: 10,
};

export const fonts: Fonts = {
  primary: 'Proxima Nova, sans-serif',
  secondary: 'Aktiv Grotesk, sans-serif',
  button: 'Proxima Nova, sans-serif',
  buttonWeight: 500,
};

const baseTheme: Theme = {
  colors: { ...colors },
  breakpoints: { ...breakpoints },
  alpha: { ...alpha },
  fonts: { ...fonts },
  headerHeight: 56,
  bannerHeight: 44,
  containerWidth: 1440,
  siteWidth: '1920px',
};

export const light: DefaultTheme = {
  ...baseTheme,
};

export const dark: DefaultTheme = {
  ...baseTheme,
};

export const shareModalTheme = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: 0,
    transform: 'translate(-50%, -50%)',
    width: '373px',
    maxWidth: '90%',
    maxHeight: '100vh',
    'overflow-x': 'hidden',
  },
  overlay: { zIndex: 3, backgroundColor: 'rgba(20, 20, 20, 0.33)' },
};

export const modalTheme = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: 0,
    transform: 'translate(-50%, -50%)',
    width: '600px',
    maxWidth: '90%',
    maxHeight: '100vh',
    'overflow-x': 'hidden',
  },
  overlay: { zIndex: 3, backgroundColor: 'rgba(20, 20, 20, 0.33)' },
};

export const largeModalTheme = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: 0,
    transform: 'translate(-50%, -50%)',
    width: '1000px',
    maxWidth: '100%',
    maxHeight: '100vh',
    overflow: 'hidden',
  },
  overlay: { zIndex: 3, backgroundColor: 'rgba(20, 20, 20, 0.33)' },
};

export const sezzleModalTheme = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: 0,
    transform: 'translate(-50%, -50%)',
    width: '800px',
    maxWidth: '100%',
    maxHeight: '100vh',
    'overflow-x': 'hidden',
    background: 'transparent',
    border: 'none',
  },
  overlay: { zIndex: 3, backgroundColor: 'rgba(20, 20, 20, 0.33)' },
};
