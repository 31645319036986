import React, { ReactNode, useContext, useEffect, useReducer, useState } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { Action, updateCart, updateUser, logout, addCustomer, updateCartWithDiscountCode } from './store.actions';
import { storeReducer } from './store.reducer';
import { getCustomer } from '../api/customer';
import { getCustomer as getStorefrontCustomer } from '../api/storefront/customer';
import { createCart, getCart, cartLineAdd } from '../api/cart';
import { getCheckout } from '../api/checkout';

import config from '../../config.json';
import { accessTokenKey, usernameKey, cartIdKey, updateCartIdWithCookieKey } from '../utils/cookies';

const { routes } = config;
declare const window: any;

export type State = {
  cart: Cart;
  user?: User;
};

const accessToken = Cookies.get(accessTokenKey);
const username = Cookies.get(usernameKey);

const initialState: State = {
  cart: {
    open: false,
    shopifyCart: undefined,
  },
  user: {
    accessToken,
    customer: undefined,
    storefrontCustomer: {
      username,
    },
  },
};

type Store = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

const store = React.createContext<Store>({
  state: initialState,
  dispatch: () => null,
});

export const useStore = (): Store => useContext(store);

const { Provider } = store;

type AppContextProps = {
  children: ReactNode;
};

export const AppContext: React.FC<AppContextProps> = ({ children }: AppContextProps) => {
  const [state, dispatch] = useReducer(storeReducer, initialState);
  const router = useRouter();
  const [hasURLDiscountCode, setHasURLDiscountCode] = useState(false);

  const updateCartWithItemsFromCheckout = (cartId: string) => {
    const checkoutId = Cookies.get('checkoutId');
    if (checkoutId) {
      getCheckout(checkoutId).then(async (checkout) => {
        if (checkout) {
          /* eslint-disable no-await-in-loop */
          for (const lineItem of checkout.lineItems.edges) {
            const { quantity, variant } = lineItem.node;

            if (variant) {
              const shopifyCart = (await cartLineAdd(cartId, variant.id, quantity).then(
                (data) => data?.cart,
              )) as Shopify.Cart;

              dispatch(updateCart(shopifyCart));
            }
          }
        }
      });
      Cookies.remove('checkoutId');
    }
  };

  const _createCart = () =>
    createCart()
      .then((data) => data?.cart)
      .then((cart) => {
        if (cart) {
          dispatch(updateCart(cart));
          Cookies.set(cartIdKey, cart.id, { expires: 90, secure: true });
          // TODO: Remove this before April 1st 2025
          updateCartWithItemsFromCheckout(cart.id);
        }
      });

  useEffect(() => {
    const accessToken = Cookies.get(accessTokenKey);
    const cartId = Cookies.get(cartIdKey);

    if (cartId) {
      getCart(cartId)
        .then((cart) => {
          if (!cart) {
            // Didn't get a cart
            _createCart();
          } else {
            // Got a cart
            const updatedCart = updateCartIdWithCookieKey(cart);
            dispatch(updateCart(updatedCart));
          }
        })

        // Errored on getting cart
        .catch(() => _createCart);
    } else {
      _createCart();
    }

    if (accessToken) {
      getCustomer(accessToken).then(async (customer) => {
        if (customer) {
          try {
            const storefrontCustomer = await getStorefrontCustomer(accessToken).then((res) => res.data);
            dispatch(updateUser(accessToken, customer, storefrontCustomer));
            dispatch(addCustomer(storefrontCustomer));
            if (storefrontCustomer.email) {
              const _learnq = (window as any)._learnq || [];
              _learnq.push([
                'identify',
                {
                  $email: storefrontCustomer.email,
                },
              ]);
              if (typeof window !== 'undefined' && window.TriplePixel) {
                window.TriplePixel('Contact', { email: customer.email });
              }
            }
          } catch (err) {
            dispatch(logout());
            router.push(routes.login);
          }
        } else {
          dispatch(logout());
          router.push(routes.login);
        }
      });
    } else {
      Cookies.remove(usernameKey);
    }
  }, []);

  useEffect(() => {
    if (!router.isReady || !state.cart.shopifyCart || hasURLDiscountCode) return;

    // ?ac=DISCOUNT_CODE
    if (router.query.ac && state.cart.shopifyCart) {
      setHasURLDiscountCode(true);
      updateCartWithDiscountCode(router.query.ac as string, state.cart);
    }
  }, [router.isReady, state.cart.shopifyCart]);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};
