import StyledLink from '@makeship/core/lib/components/Link';
import React, { useEffect, useRef } from 'react';
import Flickity from 'react-flickity-component';
import styled from 'styled-components';
import posthog from 'posthog-js';
import Social, { defaultSocialLinks } from '../../../Social';
import { H5, Caption, H1, S1, H3, P2 } from '../../../Typography';
import Container from '../../../Container';

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Cards = styled(Container)`
  width: 1920px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 128px 64px 120px 64px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 72px 16px;
  }
`;

const Card = styled.div`
  margin: 12px;
  flex-basis: 40%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-basis: 100%;
    margin: 12px 0;
  }
`;

const GlassdoorCard = styled(Card)`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  order: 1;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    order: 2;
  }
`;

const GlassdoorHeading = styled(H5)`
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral1};
  margin: 24px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 0px 0 24px;
  }
`;

const GlassdoorImage = styled.img`
  width: 50%;
`;

const GlassdoorText = styled(Caption)`
  color: ${({ theme }) => theme.colors.neutral1};
`;

const GlassdoorButtonLink = styled(StyledLink.TertiaryButton)`
  border-radius: 30px;
  margin-top: 32px;
`;

const QuoteCard = styled(Card)`
  order: 2;
  flex-grow: 1;
  background: rgba(102, 142, 255, 0.6);
  mix-blend-mode: normal;
  border-radius: 30px;
`;

const StyledFlickity = styled(Flickity)`
  height: 100%;
  > ol {
    position: static;
    margin-bottom: 12px;
  }

  > button {
    display: none;
  }
`;

const Quote = styled.div`
  padding: 32px 44px;
  width: 100%;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 32px;
  }
`;

const QuoteIcon = styled(H1)`
  color: ${({ theme }) => theme.colors.primary};
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-size: 40px;
  line-height: 40px;
`;

const QuoteBody = styled(H5)`
  margin: 16px 0;
`;

const MediumQuoteBody = styled(H5)`
  margin: 16px 0;
  font-size: 20px;
  line-height: 26px;
`;

const LongQuoteBody = styled(H5)`
  margin: 16px 0;
  font-size: 18px;
  line-height: 22px;
`;

const QuoteAuthor = styled(S1)`
  color: ${({ theme }) => theme.colors.primary};
`;

const CEOCard = styled(Card)`
  order: 3;
  padding: 48px 44px;
  display: flex;
  flex-grow: 1;
  background-color: #e8e0fd;
  border-radius: 30px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: column;
    padding: 32px;
  }
`;

const CEOCardText = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const SocialCard = styled(Card)`
  order: 4;
  padding: 64px 44px;
  background-color: #f7f4fc;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  a > div {
    height: 40px;
    width: 40px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px;
    padding-right: 48px;
  }
`;

const SocialCardHeading = styled(H3)`
  margin-bottom: 24px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    font-size: 24px;
    line-height: 31px;
  }
`;

interface FlickityExtended extends Flickity {
  slider?: HTMLElement;
}

const CareerCards: React.FC = () => {
  const flickityRef = useRef<FlickityExtended>();

  const handleOnChange = () => {
    posthog.capture('careers_employeeTestimony_swipe', {
      selected_slide_index: flickityRef.current?.selectedIndex,
    });
  };

  useEffect(() => {
    if (flickityRef.current) {
      flickityRef.current.on('change', handleOnChange);
    }
    return () => {
      if (flickityRef.current) {
        flickityRef.current.off('change', handleOnChange);
      }
    };
  }, []);

  return (
    <CardsContainer>
      <Cards>
        <GlassdoorCard>
          <GlassdoorHeading>Glassdoor Rating</GlassdoorHeading>
          <GlassdoorImage src="/assets/careers/careers-stars.svg" />
          <GlassdoorText>4.6/5</GlassdoorText>
          <GlassdoorButtonLink
            href="https://makeship.bamboohr.com/jobs/"
            onClick={() => posthog.capture('careers_viewPositionsButton_click')}
          >
            View Open Positions
          </GlassdoorButtonLink>
        </GlassdoorCard>
        <QuoteCard>
          <StyledFlickity
            flickityRef={(ref) => {
              flickityRef.current = ref;
            }}
            elementType="div"
          >
            <Quote>
              <QuoteIcon>&ldquo;</QuoteIcon>
              <QuoteBody>
                It&apos;s a privilege to work alongside such a motivated and energizing team, and contributing to our
                incredible growth has been tremendously exciting!
              </QuoteBody>
              <QuoteAuthor>- Ethan, Sales</QuoteAuthor>
            </Quote>
            <Quote>
              <QuoteIcon>&ldquo;</QuoteIcon>
              <QuoteBody>
                At Makeship, I&apos;ve discovered what it means to truly enjoy coming to work each day. It&apos;s an
                incredible feeling to collaborate with passionate people who care about the creator economy, all while
                being part of a culture that encourages authenticity.
              </QuoteBody>
              <QuoteAuthor>- Madison, Marketing</QuoteAuthor>
            </Quote>
            <Quote>
              <QuoteIcon>&ldquo;</QuoteIcon>
              <MediumQuoteBody>
                Filled with amazing people and an abundance of opportunities, Makeship has been (and will forever be) a
                highlight of my career. Not only am I excited week after week to work with individuals who share my
                values, inspire me to learn, and make work enjoyable, but I&apos;ve been able to grow professionally and
                personally at rocket-like speeds which I&apos;m grateful for.
              </MediumQuoteBody>
              <QuoteAuthor>- Wallace, Tech</QuoteAuthor>
            </Quote>
            <Quote>
              <QuoteIcon>&ldquo;</QuoteIcon>
              <LongQuoteBody>
                Everyday I am so happy to work with such amazing individuals at a company that truly values teamwork,
                professional development and fostering an innovative and creative work culture. The Makeship community
                is one of the most welcoming, enthusiastic and genuine communities I&apos;ve ever been a part of. Our
                strategic focus on continuous growth and enhancement within the creator economy, as well as the ability
                to see the direct impact of your work within the company is why I&apos;m proud to work at Makeship. I am
                excited to continue to make ship happen!
              </LongQuoteBody>
              <QuoteAuthor>- Maddie, Finance</QuoteAuthor>
            </Quote>
          </StyledFlickity>
        </QuoteCard>
        <CEOCard>
          <CEOCardText>
            <P2>
              We believe the world is transitioning to a creator-first economy and that creativity will be valued more
              than anything else in the future. Makeship is accelerating that transition by empowering any creator to
              bring a product campaign experience rivalling the biggest stars in the world. We want to do this
              sustainably and with the community as the centre piece of every successful campaign.
            </P2>
            <S1>- Rakan Al-Shawaf (CEO &amp; Co-Founder)</S1>
          </CEOCardText>
        </CEOCard>
        <SocialCard>
          <SocialCardHeading>See what Makeship is up to.</SocialCardHeading>
          <Social links={defaultSocialLinks} socialLocation="careers" />
        </SocialCard>
      </Cards>
    </CardsContainer>
  );
};

export default CareerCards;
