import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { utils } from '@makeship/core';

const Wrapper = styled.label`
  /* Customize the checkbox container */
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding: 8px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-self: center;
  width: 23px;
`;

export const Input = styled.input`
  /* Hide the browser's default checkbox */
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const Box = styled.div<{ isCreatorBranded?: boolean }>`
  /* Create a custom checkbox */
  position: absolute;
  top: 0;
  left: 0;
  height: 23px;
  width: 23px;
  background-color: ${({ theme }) => theme.colors.neutral1};
  border: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  border-radius: 3px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    top: -4px;
  }

  &:after {
    /* Create the checkbox (hidden when not checked) */
    content: '';
    position: absolute;
    display: none;

    /* Style the checkbox */
    left: 7px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &:before {
    /* Create the checkbox outline (hidden when not focused) */
    content: '';
    position: absolute;
    display: none;

    /* Style the checkbox outline */
    position: absolute;
    width: calc(100% + 5px);
    height: calc(100% + 5px);
    transform: translate(-50%, -50%);
    border: 2px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
    border-radius: 3px;
    top: 50%;
    left: 50%;
    content: '';
  }

  /* On mouse-over and focus, add a grey background color */
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    ${Input}:hover ~ &,
    ${Input}:focus-visible ~ & {
      background-color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
      border: 1px solid transparent;
    }
  }

  /* When the checkbox is selected, add a blue background */
  ${Input}:checked ~ & {
    background-color: ${({ theme, isCreatorBranded }) =>
      isCreatorBranded ? theme.colors.neutral7 : theme.colors.primary} !important;
    border: 1px solid transparent;
  }

  /* Show the checkbox on hover, checked and focused */
  ${Input}:hover ~ &:after, 
  ${Input}:checked ~ &:after, 
  ${Input}:focus-visible ~ &:after, 
  ${Input}:focus-visible ~ &:before {
    display: block;
  }
`;

export type CheckboxProps = {
  handleCollectionProductSelection: (product: Shopify.Product) => void;
  product: Shopify.Product;
  value: boolean;
  isCreatorBranded?: boolean;
};

const Checkbox: React.FC<CheckboxProps> = ({
  handleCollectionProductSelection,
  product,
  value,
  isCreatorBranded,
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(value);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  const changeHandler = () => {
    handleCollectionProductSelection(product);
  };

  return (
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <Input type="checkbox" checked={isChecked} onChange={changeHandler} />
      <Box isCreatorBranded={isCreatorBranded} />
    </Wrapper>
  );
};

export default Checkbox;
