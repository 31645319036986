import React, { useContext, useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styled from 'styled-components';
import { utils } from '@makeship/core';
import { P2, P2Styles } from '../Typography';
import { useStore } from '../../store';
import { hideCart, quantityModifier, clientDecreaseQuantity, clientIncreaseQuantity } from '../../store/store.actions';
import { parseMetafields, hasProductTag, getProductType } from '../../utils/product';

import Stepper from '../Stepper';
import { getPricePerQuantity } from '../../utils/cart';
import { analyticsTrackAddToCart, analyticsTrackRemoveFromCart } from '../../utils/analytics';
import { CartEventLocations, ProductTag, ProductType } from '../../types/common';
import SpamFlaggingContext from '../../context/spamFlagging';
import { getFormattedAmount } from '../../utils/accounting';

const CartProductWrapper = styled.div<{ first: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 0;
  padding-top: 16px;

  ${({ first, theme }) =>
    !first && `border-top: 1px solid ${utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};`}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 8px 0;
  }
`;

const ProductImageWrapper = styled.div`
  width: 110px;
  height: 110px;
`;

const CartProductInfo = styled.div`
  margin-left: 8px;
  width: 100%;
`;

const CartProductRow = styled.div<{ shouldShowVariantTitle: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${({ shouldShowVariantTitle }) => !shouldShowVariantTitle && `margin-bottom: 8px;`}
`;

const CartProductLink = styled.a`
  ${P2Styles}
  display:block;
  cursor: pointer;
`;

const CartProductPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;

const CartProductPrice = styled(P2)<{ sale: boolean }>`
  ${({ sale }) => sale && `text-decoration: line-through;`}
  ${({ sale, theme }) => sale && `color: ${utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};`}
`;

const CartProductSalePrice = styled(P2)`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.error};
`;

const CustomStepperWrapper = styled.div`
  margin-left: auto;
`;

type CartProductProps = {
  lineItem: Shopify.CartLine;
  index: number;
};

const CartProduct: React.FC<CartProductProps> = ({ lineItem, index }: CartProductProps) => {
  const { state, dispatch } = useStore();
  const { incrementSpamCounter, isSpam } = useContext(SpamFlaggingContext);
  const metafields = parseMetafields(lineItem.merchandise?.product.metafields);
  const isLimited = lineItem.merchandise
    ? hasProductTag(lineItem.merchandise?.product?.tags, ProductTag.Limited)
    : false;
  const [totalBeforeDiscounts, setTotalBeforeDiscounts] = useState(0.0);
  const [totalAfterDiscounts, setTotalAfterDiscounts] = useState(0.0);
  const productType = getProductType(lineItem.merchandise?.product.productType || '');
  const shouldShowVariantTitle = productType === ProductType.Hoodie || productType === ProductType.Crewneck;

  useEffect(() => {
    const appliedDiscountsAllocations = lineItem.discountAllocations.reduce(
      (total, discount) =>
        total + (!isNaN(discount.discountedAmount.amount) ? parseFloat(discount.discountedAmount.amount) : 0),
      0,
    );
    const compareAtPriceDiscount = lineItem.cost.compareAtAmountPerQuantity
      ? lineItem.cost.compareAtAmountPerQuantity?.amount - lineItem.cost.amountPerQuantity.amount
      : 0;

    const tempTotalBeforeDiscounts = lineItem.cost.compareAtAmountPerQuantity
      ? lineItem.cost.compareAtAmountPerQuantity?.amount * lineItem.quantity
      : lineItem.cost.amountPerQuantity.amount * lineItem.quantity;

    const tempTotalAfterDiscounts = lineItem.cost.compareAtAmountPerQuantity
      ? (lineItem.cost.compareAtAmountPerQuantity?.amount - compareAtPriceDiscount) * lineItem.quantity -
        appliedDiscountsAllocations
      : lineItem.cost.amountPerQuantity.amount * lineItem.quantity - appliedDiscountsAllocations;

    setTotalBeforeDiscounts(tempTotalBeforeDiscounts || 0);
    setTotalAfterDiscounts(tempTotalAfterDiscounts || 0);
  }, [lineItem]);

  const isLimitedQtyOutOfStock = () => {
    if (!isLimited) return false;
    if (metafields.inventory) {
      return metafields.inventory - lineItem.quantity <= 0;
    }
  };

  const isOutOfStock = isLimitedQtyOutOfStock();

  const renderItemPrice = () =>
    totalBeforeDiscounts > totalAfterDiscounts || lineItem.cost.compareAtAmountPerQuantity ? (
      <CartProductPriceWrapper>
        <CartProductPrice sale>${getFormattedAmount(totalBeforeDiscounts.toString())}</CartProductPrice>
        <CartProductSalePrice>${getFormattedAmount(totalAfterDiscounts.toString())}</CartProductSalePrice>
        <CustomStepperWrapper>
          <Stepper
            value={lineItem.quantity}
            increase={() => {
              if (!isSpam && lineItem.merchandise) {
                const { product } = lineItem.merchandise;
                incrementSpamCounter();
                analyticsTrackAddToCart(
                  product,
                  1,
                  state.user,
                  CartEventLocations.CartPopout,
                  lineItem.merchandise,
                  metafields.campaignID,
                );
              }
              dispatch(clientIncreaseQuantity(lineItem, state.cart));
              quantityModifier(lineItem, state.cart).then((action) => dispatch(action));
            }}
            decrease={() => {
              analyticsTrackRemoveFromCart(lineItem, state.user, CartEventLocations.CartPopout);
              dispatch(clientDecreaseQuantity(lineItem, state.cart));
              quantityModifier(lineItem, state.cart).then((action) => dispatch(action));
            }}
            isLimited={isLimited}
            isLimitedQtyOutOfStock={isOutOfStock}
          />
        </CustomStepperWrapper>
      </CartProductPriceWrapper>
    ) : (
      <CartProductPriceWrapper>
        <CartProductPrice sale={false}>
          ${getPricePerQuantity(lineItem.cost.amountPerQuantity.amount, lineItem.quantity)}
        </CartProductPrice>
        <CustomStepperWrapper>
          <Stepper
            value={lineItem.quantity}
            increase={() => {
              if (!isSpam && lineItem.merchandise) {
                const { product } = lineItem.merchandise;
                incrementSpamCounter();
                analyticsTrackAddToCart(
                  product,
                  1,
                  state.user,
                  CartEventLocations.CartPopout,
                  lineItem.merchandise,
                  metafields.campaignID,
                );
              }
              dispatch(clientIncreaseQuantity(lineItem, state.cart));
              quantityModifier(lineItem, state.cart).then((action) => dispatch(action));
            }}
            decrease={() => {
              analyticsTrackRemoveFromCart(lineItem, state.user, CartEventLocations.CartPopout);
              dispatch(clientDecreaseQuantity(lineItem, state.cart));
              quantityModifier(lineItem, state.cart).then((action) => dispatch(action));
            }}
            isLimited={isLimited}
            isLimitedQtyOutOfStock={isOutOfStock}
          />
        </CustomStepperWrapper>
      </CartProductPriceWrapper>
    );

  return state.cart.open ? (
    <CartProductWrapper first={!index}>
      <ProductImageWrapper>
        <Image
          src={lineItem.merchandise?.image?.transformedSrc || '/assets/default_product_asset.png'}
          alt={lineItem.merchandise?.image?.altText || ''}
          width={110}
          height={110}
          quality={90}
          unoptimized
        />
      </ProductImageWrapper>
      <CartProductInfo>
        <CartProductRow shouldShowVariantTitle={shouldShowVariantTitle}>
          <Link href={`/products/${lineItem.merchandise?.product.handle}`} passHref>
            <CartProductLink onClick={() => dispatch(hideCart())}>{lineItem.merchandise.product.title}</CartProductLink>
          </Link>
        </CartProductRow>
        {shouldShowVariantTitle && <P2>Size {lineItem.merchandise?.title}</P2>}
        {renderItemPrice()}
      </CartProductInfo>
    </CartProductWrapper>
  ) : (
    <></>
  );
};

export default CartProduct;
