import Cookies from 'js-cookie';
import {
  cartLineAdd,
  cartLinesAdd,
  cartLinesRemove,
  cartLinesUpdate,
  getCart,
  cartDiscountCodesUpdate,
} from '../api/cart';
import { getCustomer } from '../api/customer';
import { accessTokenKey, usernameKey, updateCartIdWithCookieKey } from '../utils/cookies';

export enum ActionTypes {
  SHOW_CART = 'SHOW_CART',
  HIDE_CART = 'HIDE_CART',
  TOGGLE_CART = 'TOGGLE_CART',
  UPDATE_CART = 'UPDATE_CART',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
}

export type Action = {
  type: ActionTypes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

export const showCart = (): Action => ({
  type: ActionTypes.SHOW_CART,
});

export const hideCart = (): Action => ({
  type: ActionTypes.HIDE_CART,
});

export const toggleCart = (): Action => ({
  type: ActionTypes.TOGGLE_CART,
});

export const updateCart = (cart: Shopify.Cart | undefined): Action => ({
  type: ActionTypes.UPDATE_CART,
  payload: cart,
});

export const updateUser = (
  accessToken: string,
  customer: Shopify.Customer | undefined | null,
  storefrontCustomer?: Customer,
): Action => ({
  type: ActionTypes.UPDATE_USER,
  payload: {
    accessToken,
    customer,
    storefrontCustomer,
  },
});

export const updateCustomer = (customer: Customer): Action => ({
  type: ActionTypes.UPDATE_CUSTOMER,
  payload: customer,
});

export const logoutUser = (): Action => ({
  type: ActionTypes.UPDATE_USER,
  payload: undefined,
});

export const addProduct = async (cart: Cart, number: number, variant?: Shopify.ProductVariant): Promise<Action> => {
  let { shopifyCart } = cart;
  if (shopifyCart && variant) {
    shopifyCart = (await cartLineAdd(shopifyCart.id, variant.id, number).then((data) => data?.cart)) as Shopify.Cart;
  }

  return updateCart(shopifyCart);
};

export const addProducts = async (cart: Cart, number: number, variants: Shopify.ProductVariant[]): Promise<Action> => {
  let { shopifyCart } = cart;

  if (shopifyCart && variants) {
    shopifyCart = (await cartLinesAdd(shopifyCart.id, variants, number).then((data) => data?.cart)) as Shopify.Cart;
  }

  return updateCart(shopifyCart);
};

export const removeProduct = async (lineItem: Shopify.CartLine, cart: Cart): Promise<Action> => {
  let { shopifyCart } = cart;
  if (shopifyCart) {
    const { id } = shopifyCart;
    shopifyCart = (await cartLinesRemove(id, [lineItem.id]).then((data) => data?.cart)) as Shopify.Cart;

    if (!shopifyCart) {
      shopifyCart = await getCart(id);
      if (shopifyCart) {
        shopifyCart = updateCartIdWithCookieKey(shopifyCart);
      }
    }
  }

  return updateCart(shopifyCart);
};

export const clientRemoveProduct = (lineItem: Shopify.CartLine, cart: Cart): Action => {
  const { shopifyCart } = cart;
  if (shopifyCart) {
    shopifyCart.lines.edges = shopifyCart.lines.edges.filter(({ node }) => node.id !== lineItem.id);
  }
  return updateCart(shopifyCart);
};

export const quantityModifier = async (lineItem: Shopify.CartLine, cart: Cart): Promise<Action> => {
  let { shopifyCart } = cart;

  const updateLineItems: Shopify.CartLineUpdateInput[] = [
    {
      id: lineItem.id,
      merchandiseId: lineItem.merchandise.id,
      quantity: lineItem.quantity,
    },
  ];

  if (shopifyCart) {
    shopifyCart = (await cartLinesUpdate(shopifyCart.id, updateLineItems).then((data) => data?.cart)) as Shopify.Cart;
  }
  return updateCart(shopifyCart);
};

const clientQuantityModifier = (lineItem: Shopify.CartLine, cart: Cart, modifier: number): Action => {
  const { shopifyCart } = cart;
  if (shopifyCart) {
    shopifyCart.lines.edges = shopifyCart.lines.edges.map((item) => {
      const newItem = item;
      if (newItem.node.id === lineItem.id) {
        newItem.node.quantity += modifier;
      }
      return newItem;
    });
    shopifyCart.lines.edges = shopifyCart.lines.edges.filter(({ node }) => node.quantity >= 0);
  }
  return updateCart(shopifyCart);
};

export const clientIncreaseQuantity = (lineItem: Shopify.CartLine, cart: Cart): Action =>
  clientQuantityModifier(lineItem, cart, 1);

export const clientDecreaseQuantity = (lineItem: Shopify.CartLine, cart: Cart): Action =>
  clientQuantityModifier(lineItem, cart, -1);

export const login = (accessToken: string, expiry: string): Promise<Action> => {
  Cookies.set(accessTokenKey, accessToken, { expires: new Date(expiry), secure: true });
  return getCustomer(accessToken).then((customer) => updateUser(accessToken, customer));
};

export const logout = (): Action => {
  Cookies.remove(accessTokenKey);
  Cookies.remove(usernameKey);
  return logoutUser();
};

export const renewAccessToken = (accessToken: string, expiry: string, customer: Shopify.Customer): Action => {
  Cookies.set(accessTokenKey, accessToken, { expires: new Date(expiry), secure: true });
  return updateUser(accessToken, customer);
};

export const addCustomer = (customer: Customer): Action => {
  Cookies.set(usernameKey, customer.username || '', { expires: new Date('2099-01-01'), secure: true });
  return updateCustomer(customer);
};

export const updateCartWithDiscountCode = async (code: string, cart: Cart): Promise<Action> => {
  let { shopifyCart } = cart;

  if (shopifyCart) {
    const { id } = shopifyCart;
    shopifyCart = (await cartDiscountCodesUpdate(id, [code]).then((data) => data?.cart)) as Shopify.Cart;
  }
  return updateCart(shopifyCart);
};
