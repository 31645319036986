import { gql } from '@apollo/client';
import { CART_FRAGMENT } from '../fragments';

export const CREATE_CART = gql`
  ${CART_FRAGMENT}
  mutation CreateCart($input: CartInput!) {
    cartCreate(input: $input) {
      cart {
        ...CartDetails
      }
    }
  }
`;

export const GET_CART = gql`
  ${CART_FRAGMENT}
  query GetCart($cartId: ID!) {
    node(id: $cartId) {
      ... on Cart {
        ...CartDetails
      }
    }
  }
`;

export const CART_LINES_ADD = gql`
  ${CART_FRAGMENT}
  mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...CartDetails
      }
    }
  }
`;

export const CART_LINES_REMOVE = gql`
  ${CART_FRAGMENT}
  mutation CartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...CartDetails
      }
    }
  }
`;

export const CART_LINES_UPDATE = gql`
  ${CART_FRAGMENT}
  mutation CartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...CartDetails
      }
    }
  }
`;

export const CART_DISCOUNT_CODES_UPDATE = gql`
  mutation CartDiscountCodesUpdate($cartId: ID!, $discountCode: [String!]!) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCode: $discountCode) {
      cart {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;
