import { logAndThrowSentryError } from '../../utils/errors';
import { ApolloClient } from '../clients';
import { GET_CHECKOUT } from './queries';

export const getCheckout = (checkoutId: string): Promise<Shopify.Checkout> =>
  ApolloClient.query<Shopify.QueryRoot>({
    query: GET_CHECKOUT,
    variables: {
      checkoutId,
    },
  }).then(({ data, error }) => {
    if (error) logAndThrowSentryError('getCheckout', error);
    return data?.node as Shopify.Checkout;
  });
