import { createContext, useContext } from 'react';
import { NavTheme } from '../types/common';

export interface PageAppearanceContextType {
  handleNavThemeChange: (navTheme: NavTheme | undefined) => void;
  handleBackgroundColourChange: (colour: string | undefined) => void;
}

export const PageAppearanceContext = createContext<PageAppearanceContextType | undefined>(undefined);

export const useNavTheme = (): PageAppearanceContextType => {
  const context = useContext(PageAppearanceContext);
  if (!context) {
    throw new Error('useNavTheme must be used within a NavThemeProvider');
  }
  return context;
};
