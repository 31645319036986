import { gql } from '@apollo/client';
import { CHECKOUT_FRAGMENT } from '../fragments';

export const GET_CHECKOUT = gql`
  ${CHECKOUT_FRAGMENT}
  query GetCheckout($checkoutId: ID!) {
    node(id: $checkoutId) {
      ... on Checkout {
        ...CheckoutDetails
      }
    }
  }
`;
